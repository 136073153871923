<template>
  <a-drawer
    width="1200"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>关联设备</b>
    </a-divider>
    <!-- 条件搜索 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="设备名称">
              <a-input v-model="queryParam.devName" placeholder="请输入" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="水表编号">
              <a-input v-model="queryParam.devSn" placeholder="请输入" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="水表类型">
              <a-select v-model="queryParam.typeName" placeholder="请选择" allow-clear>
                <a-select-option v-for="(item,key) in typeList" :key="key" :value="item.name">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="设备编号">
              <a-input v-model="queryParam.devCode" placeholder="请输入" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="设备状态">
              <a-select v-model="queryParam.devStatus" placeholder="请选择">
                <a-select-option v-for="(item,key) in [{name:'正常',id:0},{name:'报警',id:1}]" :key="key" :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 数据展示 -->
    <a-table
      ref="table"
      size="small"
      :components="drag(columns)"
      :loading="loading"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :pagination="false"
      :bordered="tableBordered">
      <!-- :scroll="{ x: 1200 }" -->
      <span slot="address" slot-scope="text, record">
        <span>{{ record.pcaName }} {{ record.addressName }}</span>
      </span>
      <span slot="devStatus" slot-scope="text, record">
        <a-tag color="green" v-if="record.devStatus == 0">正常</a-tag>
        <a-tag color="red" v-else-if="record.devStatus == 1">报警</a-tag>
      </span>
      <!-- 暂不使用此解绑方式 -->
      <!-- <span slot="operation" slot-scope="text, record" v-if="getIncludes(record.id)">
        <a @click="handleDelete(record.id)" v-hasPermi="['wisdom:apiuserdev:remove']">
          解绑
        </a>
      </span> -->

    </a-table>
    <!-- 分页 -->
    <a-pagination
      class="ant-table-pagination"
      show-size-changer
      show-quick-jumper
      :current="queryParam.pageNum"
      :total="total"
      :page-size="queryParam.pageSize"
      :showTotal="total => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      @change="changeSize"
    />
    <div class="bottom-control">
      <a-space>
        <a-button type="primary" :loading="submitLoading" @click="submitForm"> 保存 </a-button>
        <a-button type="dashed" @click="onClose"> 取消 </a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>

import { devicePage, deviceTypeList } from '@/api/pages/device'
import { userDevicePage, userDeviceAdd, userDeviceDel } from '@/api/pages/openuser'
import { tableMixin } from '@/store/table-mixin'
import tableDragResize from '@/utils/tableDragResize'
export default {
  name: 'Device',
  props: {
  },
  mixins: [tableMixin, tableDragResize],
  data () {
    return {
      submitLoading: false,
      open: false,
      loading: false,
      total: 0,
      list: [],
      typeList: [],
      // 查询参数
      queryParam: {
        pageNum: 1,
        pageSize: 10
      },
      queryParam2: {
        userId: null,
        pageNum: 1,
        pageSize: 999999
      },
      selectedRowKeysUser: [],
      selectedRowKeys: [],
      columns: [
        {
          title: '设备名称',
          width: 160,
          dataIndex: 'devName',
          align: 'center',
          ellipsis: true
        },
        {
          title: '水表编号',
          width: 160,
          dataIndex: 'devSn',
          align: 'center',
          ellipsis: true
        },
        {
          title: '水表类型',
          width: 120,
          dataIndex: 'typeName',
          align: 'center',
          ellipsis: true
        },
        {
          title: '设备编号',
          width: 160,
          dataIndex: 'devCode',
          align: 'center',
          ellipsis: true
        },
        {
          title: '余额',
          width: 100,
          dataIndex: 'balance',
          align: 'center',
          ellipsis: true
        },
        {
          title: '地址',
          width: 200,
          dataIndex: 'address',
          scopedSlots: { customRender: 'address' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '设备状态',
          width: 100,
          dataIndex: 'devStatus',
          scopedSlots: { customRender: 'devStatus' },
          align: 'center',
          ellipsis: true
        }
      ]
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getIncludes(id) {
      this.selectedRowKeysUser.includes(id)
    },
    onClose () {
      this.open = false
      this.selectedRowKeysUser = []
      this.selectedRowKeys = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10
      }
      this.queryParam2 = {
        userId: null,
        pageNum: 1,
        pageSize: 999999
      }
    },
    info (id) {
      this.open = true
      this.queryParam2.userId = id
      deviceTypeList().then(response => {
        this.typeList = response.data
      })
      this.getList()
      this.getUserDevList()
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    getUserDevList() {
      userDevicePage(this.queryParam2).then(res => {
          const list = res.data.records
          const arr = []
          list.map(item => {
            arr.push(item.devId)
          })
          console.log(arr)
          this.selectedRowKeysUser = arr
          this.selectedRowKeys = arr
        })
    },
    getList() {
      this.loading = true
      devicePage(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(e) {
      console.log(e)
      this.selectedRowKeys = e
    },
    /** 解绑按钮操作 */
    handleDelete(id) {
      var that = this
      this.$confirm({
        title: '提示',
        content: '确认解绑当前设备?',
        onOk() {
          return userDeviceDel({ id })
            .then(() => {
              that.getList()
              that.$message.success('解绑成功', 3)
            })
        },
        onCancel() { }
      })
    },
    /** 提交按钮 */
    submitForm () {
        this.submitLoading = true
        if (this.selectedRowKeys.length < 1) {
          this.$message.warning('请选择设备！', 3)
          this.submitLoading = false
          return false
        }
        userDeviceAdd({
          userId: this.queryParam2.userId,
          devIds: this.selectedRowKeys
        })
          .then((response) => {
            console.log(response)
            if (response.code === '20001') {
              this.$message.success('保存成功', 3)
              this.onClose()
              this.$emit('ok')
            } else {
              this.$message.warning(response.message, 3)
            }
          })
          .finally(() => {
            this.submitLoading = false
          })
    }
  }
}
</script>
<style lang="less" scoped>
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>
